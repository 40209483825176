<template>
  <b-card-code>
    <div v-if="$can('date & time','Admission')" class="custom-search d-flex">
      <b-button variant="outline-primary" @click="showModal()">
        {{ $t('Add New') }}
      </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="admission_dates"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button variant="primary" @click="selectedRow(props.row)">
                <feather-icon class="text-body " icon="Edit2Icon" size="15" />
              </b-button>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
             hide-footer
             :title="mode==='add'?'Add New':'Edit Information'"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form ref="exam_routine_add" style="min-height: 350px">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>Select Class </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="class"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">{{cls.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <b-card-text>
                <span>Select Versions </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="version"
                  rules="required"
              >
                <select name="version_id" v-model="version_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">{{version.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <b-card-text>
                <span>Select Wing </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="wing"
                  rules="required"
              >
                <select name="wing_id" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(wing, key) in wings" :value="wing.id" :key="key">{{wing.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Student Groups </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="student group"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(sgrp, key) in student_groups" :value="sgrp.id" :key="key">{{sgrp.name}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>Select Academic Years </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="academic year"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option  :value="null">Select One</option>
                  <option v-for="(ayear, key) in academic_years" :value="ayear.id" :key="key">{{ayear.year}}</option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Admission Date</span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="admission date"
                    rules="required"
                >
                  <b-form-datepicker
                      v-model="admission_date"
                      name="admission_date"
                      :state="errors.length > 0 ? false:null"
                      placeholder="admission date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>Start Time</span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="start_time"
                    rules="required"
                >
                  <b-form-timepicker
                      v-model="start_time"
                      name="start_time"
                      :state="errors.length > 0 ? false:null"
                      placeholder="start time"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <b-card-text>
                  <span>End Time</span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    name="end time"
                    rules="required"
                >
                  <b-form-timepicker
                      v-model="end_time"
                      name="end_time"
                      :state="errors.length > 0 ? false:null"
                      placeholder="end time"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col md="4" class="mt-2 pt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="addExamRoutine"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect,BRow, BCol,
  BCardText,BModal,BFormDatepicker,BFormTimepicker
} from 'bootstrap-vue'
import {required,integer} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'AdmissionDateAndTime',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,BModal,
    BButton,BFormDatepicker,BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol,BCardText, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      admission_date: null,
      start_time:null,
      end_time:null,
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Date',
          field: 'admission_date',
        },
        {
          label: 'Start Time',
          field: 'start_time',
        },
        {
          label: 'End Time',
          field: 'end_time',
        },
        {
          label: 'Class',
          field: 'classes.name',
        },
        {
          label: 'Version',
          field: 'version.name',
        },
        {
          label: 'Wing',
          field: 'wing.name',
        },
        {
          label: 'Student Group',
          field: 'student_group.name',
        },
        {
          label: 'Academic year',
          field: 'academic_year.year',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      integer,
      classes:[],
      versions:[],
      student_groups:[],
      wings:[],
      student_group_id:null,
      // version_id:null,
      classes_id:null,
      class_period_id:null,
      academic_year_id:null,
      wing_id:null,
      columns10: [
        {
          label: 'Date',
          field: 'AdmissionDate',
        },
        {
          label: 'Start Time',
          field: 'StartTime',
        },
        {
          label: 'End Time',
          field: 'EndTime',
        },
        {
          label: 'Class',
          field: 'Class',
        },
        {
          label: 'Version',
          field: 'Version',
        },
        {
          label: 'Wing',
          field: 'Wing',
        },
        {
          label: 'Student Group',
          field: 'StudentGroup',
        },
        {
          label: 'Academic Year',
          field: 'AcademicYear',
        },
        {
          label: 'Amount',
          field: 'Amount',
        },
      ],
      mode:'add',
    }
  },
  methods:{
    showModal() {
      this.selected_row={};
      this.classes_id=null;
      // this.version_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.academic_year_id=null;
      this.admission_date=null;
      this.start_time=null;
      this.end_time=null;
      this.mode='add';
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    selectedRow(row){
      this.selected_row=row;
      this.classes_id=row.classes_id;
      // this.version_id=row.version_id;
      this.wing_id=row.wing_id;
      this.student_group_id=row.student_group_id;
      this.academic_year_id=row.academic_year_id;
      this.admission_date=row.admission_date;
      this.start_time=row.start_time;
      this.end_time=row.end_time;
      this.mode='edit';
      this.$refs['my-modal'].show();
    },
    addExamRoutine(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.exam_routine_add);
          if(!this.selected_row.id){
            apiCall.post('/admission/date/store',data).then((response)=>{
              if (response.data.status === 'success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_ADMISSION_DATE');
                this.$refs.simpleRules.reset();
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }else this.$toaster.error(error.response.data.message);
            });
          }else{
            apiCall.post(`/admission/date/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status === 'success'){
                this.hideModal();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_ADMISSION_DATE');
                this.$refs.simpleRules.reset();
              }else {
                this.$toaster.error(response.data.message);
              }
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }else this.$toaster.error(error.response.data.message);
            });
          }
        }

      })
    },
    /*
    * method for get all active class wise version, shift, student groups
    * */
    async getAll(){
      await apiCall.get('/get/active/class/with/v/s/sg/wings').then((response)=>{
        this.classes = response.data;
      }).catch(()=>{
        this.classes=[];
      });
    },
    pdfGenerate(){
      /*only change able*/
      let title="Admission date and time";
      let clm=['AdmissionDate','StartTime','EndTime','Class','Version','Wing','StudentGroup', 'AcademicYear'];
      /*change able end*/
      let data =new FormData();
      data.append('title',title);
      data.append('columns',JSON.stringify(clm));
      data.append('data',JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
        const content = response.headers['content-type'];
        download(response.data,'admissionDate.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['admission_dates','academic_years']),
    getData(){
      return this.admission_dates.map(item => ({
        Class: item.classes?item.classes.name:'',
        Version: item.version?item.version.name:'',
        Wing: item.wing?item.wing.name:'',
        StudentGroup: item.student_group?item.student_group.name:'',
        AcademicYear: item.academic_year?item.academic_year.year:'',
        AdmissionDate: item.admission_date?item.admission_date:'',
        StartTime: item.start_time?item.start_time:'',
        EndTime: item.end_time?item.end_time:'',
      }));
    },
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_ADMISSION_DATE');
    this.getAll();
    this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
  watch:{
    /*watcher for class on change*/
    classes_id(){
      let s_classes = this.classes.find(item=>item.id===this.classes_id);
      this.version_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.subject_id=null;
      if (s_classes){
        this.versions = s_classes.versions??[];
        this.wings = s_classes.wings??[];
        this.student_groups = s_classes.student_groups??[];
      }else {
        this.versions =[];
        this.wings =[];
        this.student_groups =[];
      }
    },
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>